import React from 'react';
import AllPosts from './filtered/All';
import ExamplePosts from './filtered/Example';

const RecentPosts = ({ hideBg, title, category = 'all' }) => {
  if (category === 'example') {
    return <ExamplePosts hideBg={hideBg} title={title} />
  } else {
    return <AllPosts hideBg={hideBg} title={title} />
  }
};

export default RecentPosts;
