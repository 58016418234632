import { Builder } from '@builder.io/react';
import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import { navigate } from 'gatsby';
import React from 'react';
import Button from '../../components/atoms/Button/Button';
import { isMadPawsStore } from '../../common/util';

const GridSteps = ({
  header,
  buttonText,
  buttonLink,
  imageWidth,
  imageHeight,
  steps
}) => {
  return (
    <Box>
      {header && (
        <Text
          textAlign={'center'}
          as={'h3'}
          mb={12}
          fontWeight={'bold'}
        >
          {header}
        </Text>
      )}
      <Flex
        flexDir={['column', 'column', 'row']}
        columns={3}
        justifyContent={'center'}
        gap={8}
      >
        {steps.map((step, index) => (
          <Flex
            key={index}
            flexDir={['row', 'row', 'column']}
            width={['full', 'full', imageWidth ?? 220]}
            position={'relative'}
            gap={{
              base: 4,
              md: 2
            }}
          >
            <Center
              top={-5}
              left={[-1, -1, -5]}
              color={isMadPawsStore() ? 'var(--tertiary-default)' : '#fff'}
              bgColor={isMadPawsStore() ? 'var(--primary-light-1x)' : '#2091B7'}
              position={'absolute'}
              fontSize={{
                base: '1rem',
                md: '1.5rem',
              }}
              fontWeight={700}
              height={['30px', '30px', '3rem']}
              borderRadius={'full'}
              width={['30px', '30px', '3rem']}
            >
              {index + 1}
            </Center>
            <Image
              flexShrink={0}
              m={0}
              src={step.image}
              objectFit={'contain'}
              w={['auto', 'auto', 'full']}
              height={[92, 92, imageHeight ?? 180]}
            />
            <Text
            fontWeight={500}
            alignSelf={'center'}
              flex={1}
              textAlign={['left', 'left', 'center']}
              style={{ textWrap: 'balance' }}
            >
              {step.title}
            </Text>
          </Flex>
        ))}
      </Flex>

      {buttonText && (
        <Center mt={6}>
          <Button
            type={'button'}
            onClick={() => navigate(buttonLink)}
            level={'ghost'}
          >
            {buttonText}
          </Button>
        </Center>
      )}
    </Box>
  );
};
export default GridSteps;

Builder.registerComponent(GridSteps, {
  name: 'Grid Steps',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Button text'
    },
    {
      name: 'buttonLink',
      type: 'string'
    },
    {
      name: 'imageWidth',
      type: 'number',
      defaultValue: 220
    },
    {
      name: 'imageHeight',
      type: 'number',
      defaultValue: 180
    },
    {
      name: 'steps',
      type: 'list',
      defaultValue: [{ title: 'Step' }],
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Dispensed by Australian registered pharmacy'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
