import React from "react"
import LoadProducts from "../components/organisms/LoadProducts/LoadProducts";
import { Builder } from '@builder.io/react'

export const BuilderLoadProducts = (props) => {
    return <LoadProducts buttonLabel={props.label} buttonLink={props.link} columns={props.columns} displayType={props.displayType} definitionType={props.definitionType} definitions={props.definitions} transparent={props.transparent} />
};

Builder.registerComponent(BuilderLoadProducts, {
    name: "Load Products",
    inputs: [
        {
            name: "displayType",
            friendlyName: 'Display Type',
            type: "string",
            helperText: "Grid recommended for a large list of products, Slider for a short list",
            enum: [
                "Grid",
                "Slider"
            ],
        },
        {
            name: "definitionType",
            friendlyName: 'Definition Type',
            type: "string",
            enum: [
                "Category ID",
                "Individual SKUs"
            ],
        },
        {
            name: "definitions",
            friendlyName: "Definitions",
            type: "longText",
            helperText: "If providing the Category ID, simply add the numerical ID of the category you need (this can be found in the URL when editing the category in BC). If providing individual SKUs, enter each SKU separated by commas (ie 'SKU1,SKU2,SKU3'). Please refresh the preview (top right corner under the publish button) to see changes."
        },
        {
            name: "columns",
            friendlyName: "Columns",
            type: "number",
            helperText: "Set the amount of columns to display. Supports 2 to 6",
            defaultValue: 4,
        },
        {
            name: "transparent",
            friendlyName: 'Transparent product cards?',
            type: "boolean",
            defaultValue: false,
        },
        {
            name: "label",
            friendlyName: 'Button label',
            type: "string",
            defaultValue: "Click me",
        },
        {
            name: "link",
            friendlyName: 'Button link',
            type: "string",
            defaultValue: "/",
        },
      ],
});