import React from "react"
import NavSocialMedia from "../components/molecules/NavSocialMedia/NavSocialMedia";
import { Builder } from '@builder.io/react'

export const BuilderNavSocialMedia = (props) => (
    <NavSocialMedia />
);

Builder.registerComponent(BuilderNavSocialMedia, {
    name: "Social Icons"
});