import React, { useEffect, useState } from 'react';
import * as styles from './TabularGrid.module.css';

import Icon from '../../atoms/Icon/Icon';
import {useMediaQuery} from "@chakra-ui/react";

const TabularGrid = ({ label, tabSection, column, mobileColumns, withoutGrayBg }) => {

  const [activeTab, setActiveTab] = useState(null);
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  const columnCount = {
    gridTemplateColumns: isMobile ? `repeat(${mobileColumns}, 1fr)` : `repeat(${column}, 1fr)`
  }

  const icons = [
    {name: 'dogs', svg: <Icon symbol="categoryDogs" />},
    {name: 'cats', svg: <Icon symbol="categoryCats" />},
    {name: 'horses', svg: <Icon symbol="categoryHorses" />},
    {name: 'birds', svg: <Icon symbol="categoryBirds" />},
  ]

  useEffect(() => {
    if (tabSection) {
      setActiveTab(0);
    }
  }, [tabSection])

  return tabSection && (
    <div className={ styles.tabularGrid }>
      <h2 className={styles.label}>{label}</h2>
      {tabSection && (
        <>
          <div className={styles.tabMenu}>
            {tabSection && tabSection.map((tab, index) => (
              <div key={index} className={styles.tabMenuItem} role="presentation" onClick={() => {
                setActiveTab(index)
              }} style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                {icons && icons.map((icon) => {
                  if (icon.name === tab.icon) return (icon.svg);
                  return null;
                })}
                <p style={{marginLeft: '.5rem'}}>{tab.label}</p>
              </div>
            ))}
          </div>
          {tabSection && tabSection.map((tab, index) => (
            <div className={`${styles.tabSection} ${activeTab === index ? styles.active : ''}`} style={column !== undefined ? columnCount : {}}>
              {tab && tab.tabItems && tab.tabItems.map((item, key) => (
                  <a key={key} className={styles.tabSectionItem} href={item.link}>
                    <div className={`${styles.tabSectionItemImg} ${withoutGrayBg ? styles.withoutBg : ''}`}>
                      <img alt={tab.label} src={item.image} />
                    </div>
                    <p>{item.label}</p>
                  </a>
              ))  }
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default TabularGrid;
