import { Builder } from '@builder.io/react';
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Container from '../../components/atoms/Container/Container';
import * as styles from './FadeTransitionIcons.module.scss';
const FadeTransitionIcons = ({ transitionDuration, backgroundColor, data }) => {
  const [index, setIndex] = useState(0);

  const [isPc] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % data.length);
    }, transitionDuration * 1000);
    return () => clearInterval(interval);
  }, [data.length, transitionDuration]);

  return (
    <Box bgColor={backgroundColor} className={styles.root}>
      <Container size={'large'}>
        <SimpleGrid
          className={isPc ? '' : 'fade-transition-icons'}
          columns={[1, 2, 2, 4]}
          columnGap={8}
          rowGap={4}
          minH={'100px'}
          pos={'relative'}
          py={6}
        >
          {data?.map((item, idx) => (
            <Flex
              key={idx}
              alignItems={'center'}
              gap={4}
              className={`${!isPc ? 'fade-item' : ''} ${
                !isPc && idx === index ? 'active' : ''
              }`}
            >
              <Image
                objectFit={'contain'}
                w={'71px'}
                h={'52px'}
                src={item.image}
                alt={item.title}
                m={0}
              />
              <Text fontWeight={'400'} fontSize={'12px'}>
                {item.title}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default FadeTransitionIcons;

Builder.registerComponent(FadeTransitionIcons, {
  name: 'Fading grid icons',
  inputs: [
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: '#F0FBFF'
    },
    {
      name: 'transitionDuration',
      type: 'number',
      defaultValue: 3,
      helperText: 'Duration of transition in seconds',
      required: true
    },
    {
      name: 'data',
      type: 'list',
      defaultValue: [{ title: 'Cats' }],
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Dispensed by Australian registered pharmacy'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
