import { Builder } from '@builder.io/react';
import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import Slider from 'react-slick';
import Icon from '../../components/atoms/Icon/Icon';
import NewProductCard from '../../components/atoms/NewProductCard/NewProductCard';
import { bcApi } from '../../helpers/bigcommerce';
import * as styles from './ProductSlider.module.scss';
import { isMadPawsStore } from '../../common/util';


const ProductSlider = ({ header, seeMoreLink, products, hideLinkOnMobile }) => {
  const [isDragging, setIsDragging] = useState(false);

  const { data: dataProduct, isLoading } = useQuery(
    ['products', products],
    async () =>
      bcApi(
        `catalog/products?is_visible=true&include=variants,options,images,custom_fields&sku:in=${products
          .map(({ sku }) => sku)
          .join(',')}`,
        'GET'
      ),
    {
      enabled: products && products.length > 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus : false
      
    }
  );

  console.log("🚀 ~ file: ProductSlider.js:14 ~ ProductSlider ~ dataProduct:", dataProduct)


    // Check if the dataProduct has data
  if (dataProduct?.status === 200) {
    // Sort data based on the SKU order from the `products` prop
    dataProduct.response.data.sort((a, b) => {
      const orderA = products.findIndex(p => p.sku === a.sku);
      const orderB = products.findIndex(p => p.sku === b.sku);
      return orderA - orderB;
    });
  }

  return (
    <Box className={styles.root}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={[4, 4, 8]}
      >
        {header && <h3>{header}</h3>}
        {seeMoreLink && (
          <Flex
            display={{
              base: hideLinkOnMobile ? 'none' : 'flex',
              sm: 'flex'
            }}
            gap={1}
            alignItems={'center'}
            as={'a'}
            href={seeMoreLink}
            color={isMadPawsStore() ? 'var(--primary-default)' : '#14617A'}
            className='underline'
          >
            <Text fontWeight={'bold'} fontSize={'15px'} whiteSpace={'nowrap'}>
              VIEW ALL
            </Text>{' '}
            <Icon symbol={isMadPawsStore() ? 'chevRightMps' : 'chevRight'} />
          </Flex>
        )}
      </Flex>

      {isLoading && (
        <Center minH={250}>
          <Spinner size={'xl'} color="teal" />
        </Center>
      )}
      {dataProduct &&
        dataProduct.status === 200 &&
        dataProduct.response.data.length > 0 && (
          <Slider
            beforeChange={() => setIsDragging(true)}
            afterChange={() => setIsDragging(false)}
            dots={false}
            infinite={false}
            autoplay={false}
            slidesToShow={4}
            slidesToScroll={1}
            centerPadding="20px"
            responsive={[
              {
                breakpoint: 800,
                settings: 'unslick' // turns off slick on screens smaller than 500px
              }
            ]}
          >
            {dataProduct.response.data.map((product, index) => (
              <NewProductCard
                name={product.name}
                image={product.images?.find(image => image.is_thumbnail)?.url_standard}
                price={product.price}
                inventoryLevel={product.inventory_level}
                key={index}
                sku={product.sku}
                hasMoreOptions={product.options.length > 0}
                url={product.custom_url.url}
                salePrice={product.sale_price}
                ratingCount={product.reviews_count}
                rating={product.reviews_rating_sum}
                intercept={isDragging}
              />
            ))}
          </Slider>
        )}
    </Box>
  );
};

export default ProductSlider;

Builder.registerComponent(ProductSlider, {
  name: 'Product slider',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Recommended products'
    },
    {
      name: 'seeMoreLink',
      type: 'string',
      defaultValue: '#'
    },
    {
      name: 'hideLinkOnMobile',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'products',
      type: 'list',
      subFields: [
        {
          name: 'sku',
          type: 'string',
          defaultValue: 'SKU'
        }
      ]
    }
  ]
});
