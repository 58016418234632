import { Flex, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import ReviewIcons from '../ReviewIcons/ReviewIcons';
import * as styles from './UserReviewMps.module.scss';

const UserReviewMps = ({
  background,
  header,
  subHeader,
  image,
  starDisplay,
  productReviews,
  fiveStarCount,
  fromReviews,
  fromName,
  platformImage,
  roundedCorners
}) => {
  return (
    <Flex
      overflow={'visible'}
      borderRadius={roundedCorners ?? '20px'}
      flexDir={['column-reverse', 'column-reverse', 'row']}
      bg={background}
    >
      <Image
        className={styles.reviewImage}
        w={['full', 'full', '50%']}
        alignSelf={['center', 'center', 'flex-end']}
        objectFit={'cover'}
        mb={0}
        src={image}
        alt={header}
      />
      <VStack
        spacing={[4]}
        pl={4}
        pr={[4, 4, 12]}
        pt={6}
        pb={['44px', 20, 20]}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <h3 className={`text-center text-balance ${styles.reviewHeader}`}>{header}</h3>
        <Text color={'#565656'} fontSize={'18px'} className="text-center text-balance">
          {subHeader}
        </Text>
        <ReviewIcons rating={starDisplay} yellow gap={[1]} size="custom" />
        <Text color={'#6B6B6B'} className="text-center text-balance">
          {fromName}
        </Text>
        <Flex flexDir={['column', 'column', 'row']} mt={'24px'} gap={['48px', 5, 8]}>
          <VStack spacing={[2, 4, 0]}>
            <Flex alignItems={'center'} gap={4.25}>
              <h2 className={`m-0 ${styles.ratingTitle}`}>{productReviews}</h2>
              <ReviewIcons rating={1} yellow size="large" />
            </Flex>
            <Image mb={2} src={platformImage} alt={header} />
            {/* <Text color={'#6B6B6B'}>from {fromReviews} reviews</Text> */}
          </VStack>
          <VStack spacing={0} gap={[2]}>
            <h2 className={`m-0 ${styles.reviewCountTitle}`}>{fiveStarCount}+</h2>
            <Flex alignItems={'center'} gap={2} color={'var(--charcoal-02);'}>
              <h4 className={styles.reviewCountContent}>5</h4>
              <ReviewIcons rating={1} yellow size="custom" />
              <h4 className={styles.reviewCountContent}>reviews</h4>
            </Flex>
          </VStack>
        </Flex>
      </VStack>
    </Flex>
  );
};

export default UserReviewMps;
