import { Builder } from '@builder.io/react';
import { Box, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';

const ImageGrid = ({ header, desktopColumns, mobileColumns, items }) => {
  return (
    <Box>
      {header && <h3>{header}</h3>}
      <SimpleGrid
        mt={[4, 4, 8]}
        columns={[mobileColumns, mobileColumns, desktopColumns]}
        gap={6}
        alignItems={'baseline'}
        justifyContent={'center'}
      >
        {items && items.map((item, index) => (
          <Box key={index} as={Link} to={item.link} cursor={'pointer'}>
            <Box as="figure" m={0}>
              <Image
                className=""
                tabIndex={0}
                borderRadius={12}
                _hover={{
                  boxShadow: '3px 2px 4px 2px #ccc',
                  transition: 'all 0.3s ease-in-out'
                }}
                w={'full'}
                m={0}
                src={item.image}
              />
            </Box>
            {item.title && (
              <Text pt={2} className="font-medium" textAlign={'center'}>
                {item.title}
              </Text>
            )}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ImageGrid;

Builder.registerComponent(ImageGrid, {
  name: 'Image Grid',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'desktopColumns',
      type: 'number',
      defaultValue: 3
    },
    {
      name: 'mobileColumns',
      type: 'number',
      defaultValue: 2
    },
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Title'
        },
        {
          name: 'link',
          type: 'url',
          defaultValue: '#'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
