// extracted by mini-css-extract-plugin
export var action = "OrderMedicationInstructionsTab-module--action--bec1e";
export var active = "OrderMedicationInstructionsTab-module--active--26181";
export var envelopeContainer = "OrderMedicationInstructionsTab-module--envelopeContainer--d9179";
export var informationContainer = "OrderMedicationInstructionsTab-module--informationContainer--e0400";
export var informationIcon = "OrderMedicationInstructionsTab-module--informationIcon--96418";
export var informationText = "OrderMedicationInstructionsTab-module--informationText--8514f";
export var informationTextContainer = "OrderMedicationInstructionsTab-module--informationTextContainer--23f3e";
export var label = "OrderMedicationInstructionsTab-module--label--26c68";
export var mailingDetails = "OrderMedicationInstructionsTab-module--mailingDetails--17adc";
export var mailingDetailsContainer = "OrderMedicationInstructionsTab-module--mailingDetailsContainer--278c3";
export var root = "OrderMedicationInstructionsTab-module--root--8316a";
export var tabMenu = "OrderMedicationInstructionsTab-module--tabMenu--73434";
export var tabMenuItem = "OrderMedicationInstructionsTab-module--tabMenuItem--6c1fc";
export var tabSection = "OrderMedicationInstructionsTab-module--tabSection--55afa";
export var tabSectionItem = "OrderMedicationInstructionsTab-module--tabSectionItem--06a22";
export var tabSectionItemImg = "OrderMedicationInstructionsTab-module--tabSectionItemImg--16847";
export var tabularGrid = "OrderMedicationInstructionsTab-module--tabularGrid--f6f44";
export var withoutBg = "OrderMedicationInstructionsTab-module--withoutBg--76d82";