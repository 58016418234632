import React from 'react';

function BestSellingProducts() {

  // const sectionQuery = useStaticQuery(graphql`
  //   query {
  //     bigCommerceGraphQL {
  //       site {
  //         bestSellingProducts {
  //           edges {
  //             node {
  //               id
  //               entityId
  //               name
  //               sku
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  // useMemo(() => {
  //   if (!products) {
  //     setProducts(sectionQuery?.bigCommerceGraphQL.site.bestSellingProducts.edges || []);
  //   }
  // }, [sectionQuery, products]);

  // useEffect(() => {
  //   if (!displayProducts) {
  //     let newProducts = products;

  //     if (typeof props.limit !== 'undefined' && parseInt(props.limit) > 0) {
  //       newProducts = newProducts.slice(0, parseInt(props.limit))
  //     }
  //     setDisplayProducts(newProducts);
  //   }
  // }, [props.limit, products, displayProducts]);

  // if (!products.length) {
  //   return null;
  // }

  return (
    <div data-bestsellingproducts>

    </div>
  );
}

export default BestSellingProducts;
