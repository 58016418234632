import React from "react"
import { Builder } from '@builder.io/react'
import TabularGrid from "../components/organisms/TabularGrid/TabularGrid";

export const BuilderTabularGrid = (props) => (
    <TabularGrid label={props.title} tabSection={props.tabSection} column={props.columns} mobileColumns={props.mobileColumns} withoutGrayBg={props.withImageContainer} />
);

Builder.registerComponent(BuilderTabularGrid, {
    name: "Tabular Grid",
    inputs: [
      {
        name: "title",
        friendlyName: 'Title',
        type: "string",
        defaultValue: "Tabular Grid",
      },
      {
        name: "tabSection",
        friendlyName: 'Tab Data',
        type: "list",
        subFields: [
          {
            name: "label",
            friendlyName: 'Label',
            type: "string",
            defaultValue: "Dogs",
          },
          {
            name: "icon",
            friendlyName: 'Icon',
            type: "string",
            enum: [
                "dogs",
                "cats",
                "horses",
                "birds"
            ],
            defaultValue: "dogs",
          },
          {
            name: "tabItems",
            friendlyName: 'Items',
            type: "list",
            subFields: [
              {
                name: 'label',
                type: 'Label',
                required: true,
                defaultValue: 'Author Label',
              },
              {
                name: 'image',
                type: 'file',
                allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                defaultValue: '',
              },
              {
                name: "link",
                friendlyName: 'Link',
                type: "string",
                defaultValue: "/",
              },
            ]
          },
        ]
      },
      {
        name: "columns",
        friendlyName: 'Columns',
        type: "string",
        defaultValue: 4,
      },
      {
        name: "mobileColumns",
        friendlyName: 'Mobile Columns',
        type: "string",
        defaultValue: 2,
      },
      {
        name: "withImageContainer",
        friendlyName: 'No Image Gray Container',
        type: "boolean",
        defaultValue: false,
      },
    ],
});