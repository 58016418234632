import { Builder } from '@builder.io/react';
import SeoContent from 'components/atoms/SeoContent/SeoContent';
import React from 'react';

const BuilderSeoContent = ({ title, excerpt, readMore }) => {
  return <SeoContent excerpt={excerpt} readMore={readMore} title={title} />;
};

Builder.registerComponent(BuilderSeoContent, {
  name: 'Seo Content',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Title'
    },
    {
      name: 'excerpt',
      type: 'richText',
      defaultValue: 'Excerpt'
    },
    {
      name: 'readMore',
      type: 'richText',
      defaultValue: 'Read More'
    }
  ]
});
