import { Builder } from '@builder.io/react';
import React from 'react';
import * as styles from './CustomList.Module.css';

const CustomList = ({
    list
}) => {
  return <ul className={styles.root}>
    {list?.map((item, index) => (
      <li key={index} className={styles.listItem}>
        {item.text}
      </li>
    ))}
  </ul>;
};

Builder.registerComponent(CustomList, {
  name: 'List with paw icon',
  inputs: [
    {
      name: 'list',
      type: 'list',
      subFields: [
        {
          name: 'text',
          type: 'string',
          defaultValue: 'List item'
        }
      ]
    }
  ]
});
