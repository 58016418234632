import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { transformWpPost } from '../../../../helpers/wordpress';
import Output from '../Output';

const sectionQuery = graphql`
  query ExampleWpRecentPosts {
    posts: allWpPost(limit: 6, sort: { fields: date }, filter: {categories: {nodes: {elemMatch: {slug: {eq: "example"}}}}}) {
      nodes {
        id
        title
        excerpt
        content
        uri
        author {
          node {
            name
            uri
            avatar {
              url
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            uri
            sourceUrl
          }
        }
        tags {
          nodes {
            uri
            name
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        comments {
          nodes {
            id
            author {
              node {
                name
                id
                url
              }
            }
            content
          }
        }
        databaseId
      }
    }
  }
`;

const Example = ({ hideBg, title }) => {
  return (
    <StaticQuery
      query={sectionQuery}
      render={({ posts }) => {
        const recentPosts = posts?.nodes?.map(post => transformWpPost(post)) || [];
        return <Output hideBg={hideBg} title={title} recentPosts={recentPosts} />
      }}
    />
  );
};

export default Example;
