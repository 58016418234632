import React from "react"
import BestSellingProducts from "../components/organisms/BestSellingProducts/BestSellingProducts";
import { Builder } from '@builder.io/react'

export const BuilderBestSellingProducts = (props) => (
    <BestSellingProducts title={props.title} buttonLink={props.link} limit={5} />
);

Builder.registerComponent(BuilderBestSellingProducts, {
    name: "Best Selling Products",
    inputs: [
        {
            name: "title",
            friendlyName: 'Title',
            type: "string",
            defaultValue: "Best Selling products",
        },
        {
            name: "link",
            friendlyName: 'Button link',
            type: "string",
            defaultValue: "/",
        },
        {
            name: "limit",
            friendlyName: 'Limit',
            type: "string",
            defaultValue: "0",
        },
    ],
});