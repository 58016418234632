import React from 'react';
import FaqSection from '../components/atoms/FaqSection/FaqSection';
import { Builder } from '@builder.io/react';

const Faq = ({ header, faq }) => {
  return <FaqSection header={header} faq={faq} />;
};

export default Faq;

Builder.registerComponent(Faq, {
  name: 'Faq',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'faq',
      type: 'list',
      subFields: [
        {
          name: 'question',
          type: 'string',
          defaultValue: 'What is the question?'
        },
        {
          name: 'answer',
          type: 'richText',
          defaultValue: 'This is the answer.'
        },
      ]
    }
  ]
});
