import { Box, Flex, Image, Input, Text, useOutsideClick } from '@chakra-ui/react';
import algoliasearch from 'algoliasearch/lite';
import { navigate } from 'gatsby';
import React from 'react';
import { Configure, InstantSearch, SearchBox } from 'react-instantsearch-hooks-web';
import Container from '../../components/atoms/Container/Container';
import * as pcStyles from './TopBanner.module.scss';
import * as mpsStyles from './TopBanner.mps.module.scss';
import TopBannerHits from './TopBannerHits';
import TopBannerSubmit from './TopBannerSubmit';
import {  storeStyle } from '../../common/util';
import { useLocation } from '@reach/router';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
);

const isMobile = () => {
  return window.innerWidth < 800;
};

const styles = storeStyle({ mpsStyles, pcStyles });

const TopBanner = ({ data }) => {
  const { data: props } = data;
  const [showSearch, setShowSearch] = React.useState(false);
  const searchRef = React.useRef(null);
  const { pathname } = useLocation();
  const isBaseUrl = pathname === '/';

  useOutsideClick({
    ref: searchRef,
    handler: () => setShowSearch(false)
  });
  const searchBarHTML = () => {
    return (
      <>
        <InstantSearch searchClient={searchClient} indexName="prod_DEFAULT" insights={true}>
          <Configure hitsPerPage={5} filters={props.searchBarFilter ?? ''} />
          <Box className="medication-search-container" pos={'relative'} maxW={'480px'} w="full">
            <Input
              as={SearchBox}
              border={'none'}
              color={'#000'}
              w={'full'}
              m={'auto'}
              mt={6}
              h={'auto'}
              placeholder={props?.searchBarPlaceholder ?? ''}
              rounded={'full'}
              px={0}
              bg={'transparent'}
              required
              onClick={e => {
                e.stopPropagation();
                setShowSearch(true);
              }}
              onSubmit={e => {
                if (e.target?.querySelector('input').value) {
                  navigate(`/search?q=${e.target?.querySelector('input').value}`);

                  setShowSearch(false);
                }
              }}
            />
            {showSearch && <TopBannerHits ref={searchRef} />}
            <TopBannerSubmit />
          </Box>
        </InstantSearch>
      </>
    );
  };
  return (
    <Box
      className={styles.root}
      backgroundColor={props.backgroundColor}
      backgroundImage={props.backgroundImage ?? ''}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      color={props.textColor}
      role="button"
      tabIndex={0}
      onClick={props.headerLink ? () => navigate(props.headerLink) : null}
    >
      <Container size="large">
        <Flex
          minH={'235px'}
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={['column', 'column', 'column', 'row']}
          pt={isMobile() ? [8, 8, 8, 0] : [16, 16, 16, 0]}
        >
          <Box
            maxWidth={{
              base: '100%',
              lg: props.limitHeaderWidth ? '50%' : '100%'
            }}
            display={props?.showSearchBar ? 'flex' : 'block'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            {props?.subHeader && (
              <Text
                m={0}
                fontSize={isMobile() ? 12 : 15}
                textAlign={['center', 'center', 'center', 'left']}
                fontWeight={'bold'}
                color={props?.subHeaderColor || ''}
              >
                {props?.subHeader}
              </Text>
            )}
            {props?.header && (
              <Box
                as="h1"
                textAlign={['center', 'center', 'center', 'left']}
                fontSize={props?.headerSize || ''}
                color={props?.textColor || ''}
                className={
                  isMobile() && isBaseUrl ? `m-0 mt-2 text-balance-mobile` : `m-0 text-balance`
                }
              >
                {props?.header}
              </Box>
            )}

            {props?.showSearchBar && searchBarHTML()}
          </Box>
          <Image
            alignSelf={['center', 'center', 'center', 'end']}
            maxW={['full', 400, props.minHeight && props.minHeight >= 235 ? 600 : 470]}
            maxH={isMobile() ? '135px' : '220px'}
            pt={[4, 8, 8, 0]}
            objectFit="contain"
            src={props?.image}
            alt="gift"
            m={0}
          />
        </Flex>
      </Container>
    </Box>
  );
};

export default TopBanner;
