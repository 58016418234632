import { Builder } from '@builder.io/react';
import React from 'react';
import UserReviewMps from '../../components/atoms/UserReviewMps/UserReviewMps';

const RatingBannerMps = props => {
  return <UserReviewMps {...props} />;
};

export default RatingBannerMps;

Builder.registerComponent(RatingBannerMps, {
  name: 'Rating Banner MPS',
  inputs: [
    {
      name: 'background',
      type: 'color',
      defaultValue: '#F6FDFF'
    },
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'subHeader',
      type: 'string',
      defaultValue: 'Sub header'
    },
    {
      name: 'fromName',
      type: 'string',
      defaultValue: 'Sofie and Jasper'
    },
    {
      name: 'starDisplay',
      type: 'number',
      defaultValue: 1,
      max: 5
    },
    {
      name: 'roundedCorners',
      type: 'number',
      max: 20
    },
    {
      name: 'productReviews',
      type: 'number',
      defaultValue: 1,
      max: 5
    },
    {
      name: 'fiveStarCount',
      type: 'number',
      defaultValue: 3000
    },
    {
      name: 'fromReviews',
      type: 'number',
      defaultValue: 1
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
    },
    {
      name: 'platformImage',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true
    },
    {
      name: 'seeMoreLink',
      type: 'string',
      defaultValue: '#'
    },
  ]
});
