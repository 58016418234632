// extracted by mini-css-extract-plugin
export var action = "OrderMedicationInstructionsTab-mps-module--action--0b0c4";
export var active = "OrderMedicationInstructionsTab-mps-module--active--ee32e";
export var address = "OrderMedicationInstructionsTab-mps-module--address--87ea9";
export var envelopeContainer = "OrderMedicationInstructionsTab-mps-module--envelopeContainer--cfa4a";
export var informationContainer = "OrderMedicationInstructionsTab-mps-module--informationContainer--8b5c9";
export var informationIcon = "OrderMedicationInstructionsTab-mps-module--informationIcon--97cab";
export var informationText = "OrderMedicationInstructionsTab-mps-module--informationText--bfc79";
export var informationTextContainer = "OrderMedicationInstructionsTab-mps-module--informationTextContainer--4e46f";
export var label = "OrderMedicationInstructionsTab-mps-module--label--7f2e3";
export var mailingDetails = "OrderMedicationInstructionsTab-mps-module--mailingDetails--45600";
export var mailingDetailsContainer = "OrderMedicationInstructionsTab-mps-module--mailingDetailsContainer--84d20";
export var root = "OrderMedicationInstructionsTab-mps-module--root--15972";
export var tabMenu = "OrderMedicationInstructionsTab-mps-module--tabMenu--52420";
export var tabMenuItem = "OrderMedicationInstructionsTab-mps-module--tabMenuItem--c1f69";
export var tabSection = "OrderMedicationInstructionsTab-mps-module--tabSection--9c483";
export var tabSectionItem = "OrderMedicationInstructionsTab-mps-module--tabSectionItem--0d55d";
export var tabSectionItemImg = "OrderMedicationInstructionsTab-mps-module--tabSectionItemImg--b7491";
export var tabularGrid = "OrderMedicationInstructionsTab-mps-module--tabularGrid--528f6";
export var withoutBg = "OrderMedicationInstructionsTab-mps-module--withoutBg--5ddd5";