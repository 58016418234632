import { Builder } from '@builder.io/react';
import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import React from 'react';
import * as styles from './TabularImageGrid.module.scss';
import { isMadPawsStore } from '../../common/util';

const TabularImageGrid = ({
  header,
  maxImageHeight,
  squareOnMobile,
  cardColor,
  padding,
  blendImageBackground,
  tabs
}) => {
  return (
    <Box className={styles.root}>
      {header && <h3 className="mb-4">{header}</h3>}
      <Tabs>
        <Box overflowX={'auto'} overflowY={'hidden'}>
          <TabList tabIndex={-1} borderBottom={'1px solid var(--greyscale-light-3-x, #EBEBEB);'}>
            {tabs?.map((tab, index) => (
              <Tab
                _hover={{
                  background: 'var(--blue-02);'
                }}
                p={0}
                tabIndex={-1}
                color={isMadPawsStore() ? 'var(--greyscale-dark-4x)' : '#5D6975'}
                _selected={{
                  color: isMadPawsStore() ? 'var(--primary-default)' : '#1990BA',
                  fontWeight: isMadPawsStore() ? 700 : 500,
                  border: 'none',
                  boxShadow: isMadPawsStore()
                    ? '0px -4px 0px 0px var(--primary-default) inset;'
                    : '0px -4px 0px 0px #1990BA inset;'
                }}
                key={index}
              >
                <Flex p={4} gap={2} tabIndex={0}>
                  <Image
                    sx={{
                      color: isMadPawsStore() ? 'var(--primary-default)' : 'initial'
                    }}
                    m={0}
                    src={tab.tabIcon}
                  />
                  {tab.title}
                </Flex>
              </Tab>
            ))}
          </TabList>
        </Box>
        <TabPanels>
          {tabs?.map(
            (tab, index) =>
              tab.items &&
              tab.items.length > 0 && (
                <TabPanel mt={4} p={0} key={index} tabIndex={-1}>
                  <SimpleGrid
                    gap={4}
                    columns={
                      tab.items.length > 4
                        ? [
                            2,
                            Math.ceil(tab.items.length / 2),
                            Math.ceil(tab.items.length / 2),
                            Math.ceil(tab.items.length / 2),
                            tab.items.length
                          ]
                        : [
                            2,
                            Math.ceil(tab.items.length / 2),
                            Math.ceil(tab.items.length / 2),
                            tab.items.length
                          ]
                    }
                  >
                    {tab.items?.map((item, index) => (
                      <Box key={index} as="a" href={item.url} tabIndex={0} cursor={'pointer'}>
                        <Box
                          as="figure"
                          m={0}
                          overflow={'hidden'}
                          borderRadius={'10px'}
                          minW={134}
                          p={padding ?? 0}
                          bg={cardColor ?? ''}
                          className="shadow"
                        >
                          <Image
                            mixBlendMode={blendImageBackground ? 'multiply' : 'normal'}
                            aspectRatio={[squareOnMobile ? 1 : 'auto', 'auto', 'auto']}
                            objectFit={squareOnMobile ? 'cover' : 'contain'}
                            m={0}
                            maxH={maxImageHeight}
                            w={'full'}
                            src={item.image}
                            alt={item.title}
                          />
                        </Box>
                        {item.title && (
                          <Text mt={2} textAlign={'center'} fontSize={14}>
                            {item.title}
                          </Text>
                        )}
                      </Box>
                    ))}
                  </SimpleGrid>
                </TabPanel>
              )
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TabularImageGrid;

Builder.registerComponent(TabularImageGrid, {
  name: 'Tabular Image Grid',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'maxImageHeight',
      type: 'number',
      defaultValue: 180
    },
    {
      name: 'cardColor',
      type: 'color',
      defaultValue: '#F9F9FB'
    },
    {
      name: 'padding',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'squareOnMobile',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'blendImageBackground',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Tab 1'
        },
        {
          name: 'tabIcon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'items',
          type: 'list',
          subFields: [
            {
              name: 'title',
              type: 'string',
              defaultValue: 'Title'
            },
            {
              name: 'image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'webp'],
              required: true
            },
            {
              name: 'url',
              type: 'url',
              required: true
            }
          ]
        }
      ]
    }
  ]
});
