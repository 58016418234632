import React from "react"
import Button from '../components/atoms/Button/Button'
import { Builder } from '@builder.io/react'

export const BuilderButton = (props) => (
    <Button href={props.link} target={props.target} theme={props.theme} level={props.level} alternate={props.alternate} type="a">{props.label}</Button>
);

Builder.registerComponent(BuilderButton, {
    name: "Styled Button",
    inputs: [
      {
        name: "label",
        friendlyName: 'Button label',
        type: "string",
        defaultValue: "Click me",
      },
      {
        name: "link",
        friendlyName: 'Button link',
        type: "string",
        defaultValue: "/",
      },
      {
        name: "level",
        friendlyName: 'Level',
        type: "string",
        enum: [
            "primary",
            "secondary",
            "tertiary"
        ],
        defaultValue: "primary",
      },
      {
        name: "theme",
        friendlyName: 'Theme',
        type: "string",
        enum: [
            "light",
            "outline"
        ],
        defaultValue: "primary",
      },
      {
        name: "target",
        friendlyName: 'Open link in new tab',
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "alternate",
        friendlyName: 'Alternate Style',
        type: "boolean",
        defaultValue: false,
      },
    ],
});