import { Builder } from '@builder.io/react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { changeOpacity } from 'helpers/general';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './OfferBanner.module.css';

const OfferBanner = ({ header, offer }) => {
  return (
    <Box>
      {header && <h3 className="mb-4">{header}</h3>}
      <Swiper
        slidesPerView={1}
        spaceBetween={16}
        pagination={{
          clickable: true
        }}
        modules={[Pagination]}
        breakpoints={{
          600: {
            slidesPerView: 2
          },
          1114: {
            slidesPerView: 4
          }
        }}
      >
        {offer.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              backgroundColor={item.background}
              backgroundImage={item.backgroundImage ?? ''}
              backgroundSize={'cover'}
              backgroundRepeat={'no-repeat'}
              borderRadius={'1rem'}
              overflow={'hidden'}
              pos={'relative'}
            >
              <Flex
                minH={267}
                direction={'column'}
                justifyContent={'space-between'}
                p={6}
                textAlign={'center'}
                mb={5}
                pb={123}
              >
                <Text
                  className="text-balance font-medium"
                  fontSize={24}
                  lineHeight={7}
                  color={item.titleColor}
                  mb={4}
                >
                  {item.title}
                </Text>
                <Button
                  as={'a'}
                  href={item.buttonLink}
                  color={item.buttonTextColor}
                  bg={item.buttonColor}
                  h={'3rem'}
                  cursor={'pointer'}
                  tabIndex={0}
                  maxW={146}
                  _hover={{
                    bg: changeOpacity(item.buttonColor, 0.8)
                  }}
                  mx={'auto'}
                  fontSize={15}
                  className="font-bold"
                  minW={150}
                  rounded={'full'}
                >
                  {item.buttonText}
                </Button>
              </Flex>

              <Image
                maxH={123}
                position={'absolute'}
                left={0}
                bottom={0}
                width={'full'}
                objectFit={'contain'}
                m={0}
                src={item.image}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default OfferBanner;

Builder.registerComponent(OfferBanner, {
  name: 'Offer Banner',
  inputs: [
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'offer',
      type: 'list',
      defaultValue: [{ title: '20% off' }],
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '20% off'
        },
        {
          name: 'titleColor',
          type: 'color',
          defaultValue: '#ffffff'
        },
        {
          name: 'buttonText',
          type: 'string',
          defaultValue: 'Shop now'
        },
        {
          name: 'buttonColor',
          type: 'color',
          defaultValue: '#14607A'
        },
        {
          name: 'buttonTextColor',
          type: 'color',
          defaultValue: '#ffffff'
        },
        {
          name: 'buttonLink',
          type: 'string',
          defaultValue: '/'
        },
        {
          name: 'background',
          type: 'color',
          defaultValue: '#8BC7DC'
        },
        {
          name: 'backgroundImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true
        }
      ]
    }
  ]
});
