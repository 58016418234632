import React from "react"
import FeaturedProducts from "../components/organisms/FeaturedProducts/FeaturedProducts";
import { Builder } from '@builder.io/react'

export const BuilderFeaturedProducts = (props) => (
    <FeaturedProducts title={props.title} buttonLink={props.link} />
);

Builder.registerComponent(BuilderFeaturedProducts, {
    name: "Featured Products",
    inputs: [
        {
          name: "title",
          friendlyName: 'Title',
          type: "string",
          defaultValue: "Featured products",
        },
        {
          name: "link",
          friendlyName: 'Button link',
          type: "string",
          defaultValue: "/",
        },
      ],
});