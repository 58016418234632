import { Builder } from '@builder.io/react';
import { Box } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { wpApi } from '../../helpers/wordpress';
import { Button, Center, Flex, SimpleGrid, Spinner } from '@chakra-ui/react';
import PostCard from '../../components/molecules/PostCard/PostCard';
import * as styles from './WPPost.module.scss';
import Icon from '../../components/atoms/Icon/Icon';
import { Link } from 'gatsby';

const WPPost = ({ title, categoryId, limit }) => {
  const [page, setPage] = useState(1);
  // const { data: wpCategory, isLoading: fetchingCategory } = useQuery(
  //   ['category_id', category],
  //   async () => wpApi(`categories?slug=${category}`),
  //   {
  //     enabled: !!category
  //   }
  // );

  // const categoryId = wpCategory?.response?.[0]?.id;

  const { data: wpPosts, isLoading: fetchingPosts } = useQuery(
    [`wp_posts_${categoryId}`, categoryId, page],
    async () =>
      wpApi(
        `posts?${
          categoryId ? `categories=${categoryId}&_embed` : `per_page=12&page=${page}&_embed`
        }`
      ),{
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      }
  );



  const { data: category } = useQuery(
    [`wp_posts_${categoryId}`, categoryId],
    async () => wpApi(`categories/${categoryId}`),
    {
      enabled: !!categoryId,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );


  const { articles, hasMore } = useMemo(() => {
    let articles = [];
    let hasMore = false;
    if (categoryId && wpPosts?.response?.length > 0) {
      if (wpPosts?.response?.length > 3) {
        hasMore = true;
      }

      articles = wpPosts?.response?.slice(0, 3);
    } else if (wpPosts?.response) {
      articles = wpPosts?.response;
    }


    return { articles : articles?.map(article=>({...article ,
    featured_image: article?._embedded?.['wp:featuredmedia']?.[0]?.source_url,
    
    })) , hasMore };
  }, [categoryId, wpPosts]);


  return (
    <Box className={styles.root} minH={'50dvh'}>
      {title && <Flex justifyContent={'space-between'} align={'center'}>
      <h1>{title}</h1>
      {!!category && hasMore && <Link  className={`underline viewLink`} style={{flexShrink: 0}} to={`/blog/category/${category?.response?.slug}`}><span>View all</span><Icon symbol={'chevRight'} /></Link> }
        </Flex>}

      {fetchingPosts && (
        <Center py={8}>
          <Spinner size={'xl'} color="teal" />
        </Center>
      )}

      <SimpleGrid mt={[4, 4, 8]} columns={[1, 2, 3 ]} gap={6} >
        {articles?.map(post => (
          <PostCard
            size="large"
            viewMode="grid"
            key={post.id}
            title={post.title.rendered}
            excerpt={post.excerpt.rendered}
            categories={post.categories}
            slug={post.slug}
            thumbnail={post.featured_image}
          />
        ))}
      </SimpleGrid>

      {!categoryId &&
        wpPosts?.pagination?.totalPages &&
        Number(wpPosts?.pagination?.totalPages) > 1 && (
          <Flex justifyContent={'center'} gap={2} mt={'4rem'}>
            <Button
              mr={2}
              className={`pagination left`}
              onClick={page === 1 ? undefined : () => setPage(page - 1)}
            >
              <Icon symbol="chevRight" />
            </Button>
            {Array.from(
              Array(Number(wpPosts?.pagination?.totalPages)).keys()
            ).map((_, index) => (
              <Button
                className={`pagination  ${page === index + 1 ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setPage(index + 1);
                }}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              ml={2}
              className={`pagination `}
              onClick={
                page === Number(wpPosts?.pagination?.totalPages)
                  ? undefined
                  : () => setPage(page + 1)
              }
            >
              <Icon symbol="chevRight" />
            </Button>
          </Flex>
        )}
    </Box>
  );
};

export default WPPost;

Builder.registerComponent(WPPost, {
  name: 'WP Posts',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Category name'
    },
    {
      name: 'categoryId',
      type: 'number',
      helperText: 'All articles will be displayed if no category is entered'
    }
  ]
});
