import { SimpleGrid } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import React, { useMemo } from 'react';
import Container from '../../atoms/Container/Container';
import PostCard from '../../molecules/PostCard/PostCard';
import * as styles from './RecentPosts.module.css';
// const sliderProps = {
//   lazyLoad: true,
//   autoplaySpeed: 4000,
//   infinite: false,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1
//       }
//     },
//     {
//       breakpoint: 550,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//       }
//     }
//   ]
// };

const classes = [styles.container];
const Output = ({ hideBg, title, recentPosts }) => {
  const { pathname } = useLocation();
  if (!hideBg) {
    classes.push(styles.background);
  }
  const classContainer = classes.join(' ');

  const ContainerComp = hideBg ? React.Fragment : Container;

  const renderBlogList = useMemo(() => {
    let filteredData = [];
    if (recentPosts) {
      filteredData = recentPosts
        .filter(item => item.slug !== pathname)
        .slice(0, 3);
    }
    return filteredData;
  }, [recentPosts, pathname]);

  return (
    <section className={styles.root}>
      <div className={classContainer}>
        <ContainerComp>
          {!hideBg && <h3 className="text-center">{title}</h3>}
          <SimpleGrid columns={[1,2,3]} gap={4}>
            {/* <Slider {...sliderProps}> */}
            {renderBlogList?.map((post, postIndex) => (
                <PostCard
                  size="large"
                  viewMode="grid"
                  key={post.id}
                  title={post.title}
                  excerpt={post.excerpt}
                  categories={post.categories}
                  slug={post.slug}
                  thumbnail={post.thumbnail_path}
                />
            ))}
            {/* </Slider> */}
          </SimpleGrid>
        </ContainerComp>
      </div>
    </section>
  );
};

export default Output;
