import { Builder } from '@builder.io/react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import Icon from '../../components/atoms/Icon/Icon';

import * as pcStyles from './OrderMedicationInstructionsTab.module.css';
import * as mpsStyles from './OrderMedicationInstructionsTab.mps.module.css';
import { isMadPawsStore, storeStyle } from '../../common/util';

const styles = storeStyle({mpsStyles, pcStyles});

const OrderMedicationInstructionsTab = ({ label, tabSection }) => {
  return (
    <>
      <Box className={styles.root}>
        {label && <h3>{label}</h3>}
        <Tabs mt={[4, 4, 8]}>
          <TabList
            borderBottom={
              isMadPawsStore()
                ? '1px solid var(--greyscale-light-4x)'
                : '1px solid var(--greyscale-light-3-x, #EBEBEB);'
            }
          >
            {tabSection?.map((tab, index) => (
              <Tab
                color={isMadPawsStore() ? 'var(--greyscale-dark-4x)' : '#5D6975'}
                _selected={{
                  color: isMadPawsStore() ? 'var(--primary-default)' : '#006E95',
                  border: 'none',
                  boxShadow: isMadPawsStore()
                    ? '0px -4px 0px 0px var(--primary-default) inset;'
                    : '0px -4px 0px 0px #006E95 inset;'
                }}
                key={index}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabSection?.map((tab, index) => (
              <TabPanel key={index} px={0} py={8}>
                <Box
                  as="ol"
                  m={0}
                  color={isMadPawsStore() ? 'var(--greyscale-dark-3x)' : '#4B5661'}
                >
                  {tab &&
                    tab.tabItems &&
                    tab.tabItems.map((item, key) => {
                      return (
                        <>
                          <li key={key} className={styles.action}>
                            <strong>{item.action}</strong> <span>{item.details}</span>
                          </li>

                          {item?.includeMailingDetails && (
                            <div className={styles.mailingDetailsContainer}>
                              <div className={styles.envelopeContainer}>
                                <Icon symbol={isMadPawsStore() ? 'envelope-mps' : 'envelope'} />
                              </div>
                              <div className={styles.mailingDetails}>
                                <p className={styles.address}>Pet Chemist Pharmacy</p>
                                <p className={styles.address}>Reply Paid 89752</p>

                                <p className={styles.address}>PO Box 999</p>
                                <p className={styles.address}>Tweed Heads NSW 2485</p>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <div className={styles.informationContainer}>
        <div className={styles.informationIcon}>
          <Icon symbol={isMadPawsStore() ? 'rx-mps' : 'rx'} />
        </div>
        <div className={styles.informationTextContainer}>
          <p className={styles.informationText}>
            For Pet Chemist Online to organise and facilitate the dispensing of prescription
            medication, we require that you post the original signed prescription to our PO Box. The
            dispensing pharmacist must be provided with the original pen signed prescription.
          </p>
        </div>
      </div>
    </>
    //   <h2 className={styles.label}>{label}</h2>
    //   {tabSection && (
    //     <>
    //       <div className={styles.tabMenu}>
    //         {tabSection &&
    //           tabSection.map((tab, index) => (
    //             <div
    //               key={index}
    //               className={styles.tabMenuItem}
    //               role="presentation"
    //               onClick={() => {
    //                 setActiveTab(index);
    //               }}
    //               style={{
    //                 display: 'flex',
    //                 alignItems: 'center',
    //                 marginBottom: '1rem'
    //               }}
    //             >
    //               <p style={{ marginLeft: '.5rem' }}>{tab.label}</p>
    //             </div>
    //           ))}
    //       </div>
    //       {tabSection &&
    //         tabSection.map((tab, index) => (
    //           <div
    //             className={`${styles.tabSection} ${
    //               activeTab === index ? styles.active : ''
    //             }`}
    //             style={column !== undefined ? columnCount : {}}
    //           >
    //             <ol>
    //               {tab &&
    //                 tab.tabItems &&
    //                 tab.tabItems.map((item, key) => {
    //                   console.log(item);
    //                   return (
    //                     <>
    //                       <li key={key} className={styles.action}>
    //                         <strong>{item.action}</strong>{' '}
    //                         <span>{item.details}</span>
    //                       </li>

    //                       {item?.includeMailingDetails && (
    //                         <div className={styles.mailingDetailsContainer}>
    //                           <div className={styles.envelopeContainer}>
    //                             <Icon symbol="envelope" />
    //                           </div>
    //                           <div className={styles.mailingDetails}>
    //                             <p>Pet Chemist Pharmacy</p>
    //                             <p>Reply Paid 89752</p>
    //                             <p>PO Box 999</p>
    //                             <p>Tweed Heads NSW 2485</p>
    //                           </div>
    //                         </div>
    //                       )}
    //                     </>
    //                   );
    //                 })}
    //             </ol>
    //           </div>
    //         ))}
    //       <div className={styles.informationContainer}>
    //         <div className={styles.informationIcon}>
    //           <Icon symbol="rx" />
    //         </div>
    //         <div className={styles.informationTextContainer}>
    //           <p className={styles.informationText}>
    //             For Pet Chemist Online to organise and facilitate the dispensing
    //             of prescription medication, we require that you post the
    //             original signed prescription to our PO Box. The dispensing
    //             pharmacist must be provided with the original pen signed
    //             prescription.
    //           </p>
    //         </div>
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};

export default OrderMedicationInstructionsTab;

Builder.registerComponent(OrderMedicationInstructionsTab, {
  name: 'Order Medication Instructions Tab',
  inputs: [
    {
      name: 'title',
      friendlyName: 'Title',
      type: 'string',
      defaultValue: ''
    },
    {
      name: 'tabSection',
      friendlyName: 'Tab Data',
      type: 'list',
      subFields: [
        {
          name: 'label',
          friendlyName: 'Label',
          type: 'string',
          defaultValue: ''
        },
        {
          name: 'tabItems',
          friendlyName: 'Items',
          type: 'list',
          subFields: [
            {
              name: 'action',
              type: 'text',
              required: true,
              defaultValue: ''
            },
            {
              name: 'details',
              type: 'text',
              required: true,
              defaultValue: ''
            },
            {
              name: 'includeMailingDetails',
              type: 'boolean',
              defaultValue: false
            }
          ]
        }
      ]
    },
    {
      name: 'columns',
      friendlyName: 'Columns',
      type: 'string',
      defaultValue: 4
    },
    {
      name: 'withImageContainer',
      friendlyName: 'No Image Gray Container',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
