import * as React from 'react';
import { graphql } from 'gatsby';
import { builder, BuilderComponent } from '@builder.io/react';
import '@builder.io/widgets';
import Seo from '../components/organisms/Seo/Seo';
import Layout from '../components/organisms/Layout/Layout';

/* Register builder components */
import '../builderComponents/Breadcrumbs';
import '../builderComponents/Button';
import '../builderComponents/FeaturedProducts';
import '../builderComponents/LoadProducts';
import '../builderComponents/RecentPosts';
import '../builderComponents/SocialIcons';
import '../builderComponents/TabularGrid';
import '../builderComponents/Faq';
// import '../builderComponents/LatestPromos'
import '../builderComponents/BestSellingProducts';
import '../builderComponents/FadeTransitionIcons/FadeTransitionIcons';
import '../builderComponents/GridSteps/GridSteps';
import '../builderComponents/PLPBanner/PLPBanner';
import '../builderComponents/RatingBanner/RatingBanner';
import '../builderComponents/RatingBannerMps/RatingBannerMps';
import '../builderComponents/BottomBanner/BottomBanner';
import '../builderComponents/OfferBanner/OfferBanner';
import '../builderComponents/ProductSlider/ProductSlider';
import '../builderComponents/OrderMedicationInstructionsTab/OrderMedicationInstructionsTab';
import '../builderComponents/VideoPlayer/VideoPlayer';
import '../builderComponents/OfferBanner/OfferBanner';
import '../builderComponents/SpecialsGrid/SpecialsGrid';
import '../builderComponents/ImageGrid/ImageGrid';
import '../builderComponents/ProductSlider/ProductSlider';
import '../builderComponents/TabularImageGrid/TabularImageGrid';
import '../builderComponents/WPPost/WPPost';
import '../builderComponents/SideNav/SideNav';
import '../builderComponents/PLPReview/PLPReview';
import '../builderComponents/SeoContent/SeoContent';
import '../builderComponents/CustomList/CustomList';
import * as styles from './BuilderPage.module.css';
import TopBanner from '../builderComponents/TopBanner/TopBanner';
import { isMadPawsStore } from '../common/util';

require('dotenv').config();
builder.init(process.env.BUILDER_PUBLIC_API);

function LandingPageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const landingPage = models.page?.[0]?.content;
  const banner = data?.allBuilderModels.oneHeaderHero?.content;
  const pageName = models?.page?.[0]?.name;
  const path = models?.page?.[0]?.query.find(q => q.property === 'urlPath');
  const metaTitle = `${landingPage && landingPage.data.title} - ${
    isMadPawsStore() ? 'Mad Paws Pet Store' : 'Pet Chemist Online'
  }`;
  return (
    <Layout>
      <Seo
        title={metaTitle}
        description={landingPage && landingPage.data.description}
        microDataType={path && path.value === '/' ? 'home' : null}
      >
        <body className={`builderPage ${pageName} ${styles.builderPage}`} />
      </Seo>

      {banner && <TopBanner data={banner} />}
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        name="page"
        content={landingPage}
        context={{ apiKey: process.env.BUILDER_PUBLIC_API }} // To support the instagram feed
      />
    </Layout>
  );
}

export default LandingPageTemplate;

export const landingPageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
        name
        query {
          value
          property
        }
      }
      oneHeaderHero(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
    }
  }
`;
