import React from "react"
import RecentPosts from "../components/organisms/RecentPosts/RecentPosts";
import { Builder } from '@builder.io/react'

export const BuilderRecentPosts = (props) => (
    <RecentPosts hideBg />
);

Builder.registerComponent(BuilderRecentPosts, {
    name: "Recent Posts"
});