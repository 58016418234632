import { Builder } from "@builder.io/react";
import React from 'react';
import ReactPlayer from 'react-player';
import * as styles from './VideoPlayer.module.css';

const VideoPlayer = ({ url }) => {
    return (
        <div className={styles.videoPlayerContainer}>
            <ReactPlayer
                className="reactPlayer"
                // url='https://www.youtube.com/watch?v=R01EkcIPkBw'
                url={url}
                width='100%'
                height='100%'
            />
        </div>
    )
};

export default VideoPlayer;

Builder.registerComponent(VideoPlayer, {
    name: "Video Player",
    inputs: [
        {
            name: "url",
            type: "string",
            required: true,
            defaultValue: "",
        }
    ],
});
