import React from "react"
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import { Builder } from '@builder.io/react'

export const BuilderBreadcrumbs = (props) => {
    const links = [{ link: '/', label:'' }];
    if (props.links) {
        links.push(...props.links);
    }
    return (
        <Breadcrumbs crumbs={links} />
    )
};

Builder.registerComponent(BuilderBreadcrumbs, {
    name: "Breadcrumbs",
    defaultStyles: {
        paddingBottom: "60px"
    },
    inputs: [
      {
        name: "links",
        friendlyName: 'Links',
        helperText: 'Enter links in the order you want to display the crumbs. Home is defaulted. Leave the link off the last item for consistency',
        type: "list",
        subFields: [
          {
            name: "label",
            friendlyName: 'Crumb label',
            type: "string",
            defaultValue: "Crumb",
          },
          {
            name: "link",
            friendlyName: 'Crumb link',
            type: "string",
            defaultValue: "/",
            helperText: 'Leave blank to omit',
          }
        ],
      },
    ],
});