import { IconButton, useToast } from '@chakra-ui/react';
import { navigate } from 'gatsby';
import React from 'react';
import { useHits, useSearchBox } from 'react-instantsearch-hooks-web';
import { isMadPawsStore } from '../../common/util';

const TopBannerSubmit = () => {
  const { query } = useSearchBox();
  const toast = useToast();
  const { hits } = useHits();
  const formSubmitHandler = () => {
    //navigate to the search page
    if (query && hits.length > 0) {
      navigate(`/search?q=${query}`);
    } else {
      toast({
        title: 'No results found',
        description: 'Please enter a valid search term',
        status: 'error',
        position: 'top-right'
      });
    }
  };

  return (
    <IconButton
      onClick={formSubmitHandler}
      top={isMadPawsStore() ? '39px' : '35px'}
      right={'24px'}
      color={'#14617A'}
      minW={'32px'}
      h={'32px'}
      zIndex={2}
      w={'32px'}
      icon={
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.25 15.8457L11.25 11.8457M7.25 13.8457C3.93629 13.8457 1.25 11.1594 1.25 7.8457C1.25 4.53199 3.93629 1.8457 7.25 1.8457C10.5637 1.8457 13.25 4.53199 13.25 7.8457C13.25 11.1594 10.5637 13.8457 7.25 13.8457Z"
            stroke={isMadPawsStore() ? 'var(--primary-default)' : "#14617A"}
            stroke-width="2"
          />
        </svg>
      }
      variant={'ghost'}
      rounded={'full'}
      position={'relative'}
      pos={'absolute'}
    />
  );
};

export default TopBannerSubmit;
