import React from 'react';
import Accordion from '../../molecules/AccordionAlt/Accordion';
import * as pcStyles from './FaqSection.module.scss';
import * as mpsStyles from './FaqSection.mps.module.scss';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });

const FaqSection = ({ faq, header }) => {
  return (
    <section className={styles.Faqs}>
      <h3>{header}</h3>
      {faq?.map((faq, index) => (
        <Accordion
          key={index}
          customStyle={styles}
          type={'add'}
          overrideSize={true}
          title={faq.question}
          defaultOpen={index === 0}
        >
          <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
        </Accordion>
      ))}
    </section>
  );
};

export default FaqSection;
