import { Builder } from '@builder.io/react';
import {
  Box,
  Divider,
  Select,
  Stack,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import React from 'react';

const SideNav = ({ header, pages }) => {
  const { pathname } = useLocation();
  const [isPc] = useMediaQuery('(min-width: 991px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  });

  return (
    <Box>
      {isPc ? (
        <>
          {header && <Text fontWeight={400}>{header}</Text>}
          <Divider my={4} pb={0.2} />
          <Stack spacing={4}>
            {pages &&
              pages.map((page, index) => (
                <Box key={index}>
                  <Link activeClassName="link-active" to={page.url}>
                    {page.title}
                  </Link>
                </Box>
              ))}
          </Stack>
        </>
      ) : (
        <Select
          rounded={'full'}
          onChange={e => navigate(e.currentTarget.value)}
        >
          {pages?.map(route => (
            <option
              key={route.url}
              value={route.url}
              selected={pathname === route.url}
            >
              {route.title}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default SideNav;

Builder.registerComponent(SideNav, {
  name: 'Side Navigation',
  inputs: [
    {
      name: 'header',
      type: 'string',
      required: true,
      defaultValue: 'Header'
    },
    {
      name: 'pages',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Title'
        },
        {
          name: 'url',
          type: 'url'
        }
      ]
    }
  ]
});
