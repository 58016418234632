import { Builder } from '@builder.io/react';
import { Flex, Image, Text, VStack } from '@chakra-ui/react';
import Button from 'components/atoms/Button/Button';
import { navigate } from 'gatsby';
import React from 'react';
const BottomBanner = ({
  background,
  header,
  subHeader,
  buttonText,
  buttonColor,
  buttonLink,
  buttonTextColor,
  align,
  maxWidth,
  image,
  subHeaderColor,
  subHeaderBottomMargin
}) => {
  return (
    <Flex
      bg={background ?? ''}
      flexDir={['column-reverse', 'column-reverse', 'row']}
      maxW={maxWidth ?? 'auto'}
      mx={'auto'}
    >
      <Image
        w={'full'}
        maxW={540}
        flex={1}
        src={image}
        alt={header}
        margin={0}
      />
      <VStack
        flex={1}
        justifyContent={'center'}
        alignItems={align ?? ''}
        gap={4}
        my={[12, 12, 0]}
      >
        {header && <h3>{header}</h3>}
        {subHeader && <Text 
        fontWeight={400}
        fontSize={18}
        mb={subHeaderBottomMargin? subHeaderBottomMargin : 0}
        color={subHeaderColor? subHeaderColor : "#4B5661"}>{subHeader}</Text>}
        {buttonText && (
          <Button level={'tertiary'} onClick={()=>navigate(buttonLink)}>
            {buttonText}
          </Button>
          
        )}
      </VStack>
    </Flex>
  );
};

export default BottomBanner;

Builder.registerComponent(BottomBanner, {
  name: 'Bottom Banner',
  inputs: [
    {
      name: 'background',
      type: 'color'
    },
    {
      name: 'header',
      type: 'string',
      defaultValue: 'Header'
    },
    {
      name: 'subHeader',
      type: 'string',
      defaultValue: 'Sub header'
    },
    {
      name: 'subHeaderColor',
      type: 'color',
      defaultValue: '#000'
    },
    {
      name: 'subHeaderBottomMargin',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'buttonColor',
      type: 'color',
      defaultValue: '#14607A'
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Chat with us'
    },
    {
      name: 'buttonTextColor',
      type: 'color',
      defaultValue: '#000'
    },
    {
      name: 'buttonLink',
      type: 'url',
      defaultValue: 'Sub header'
    },
    {
      name: 'align',
      type: 'string',
      enum: ['flex-start', 'center', 'flex-end']
    },
    {
      name: 'maxWidth',
      type: 'number',
      defaultValue: 1200
    },
    {
      name: 'image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true
    }
  ]
});
