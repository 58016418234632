import { Builder } from '@builder.io/react';
import { Box, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';

const SpecialsGrid = ({ header, desktopColumns, mobileColumns, items }) => {
  return (
    <Box>
      {header && <h3>{header}</h3>}
      <SimpleGrid
        mt={[4, 4, 10]}
        columns={[mobileColumns, mobileColumns,mobileColumns, desktopColumns]}
        gap={[4, 4, 6]}
        cursor={'pointer'}
        justifyContent={'center'}
      >
        {items?.map((item, index) => (
          <Flex
      
            key={index}
            as={Link}
            _focusVisible={{
              outline: '2px solid var(--blue-02)'
            }}
            tabIndex={0}
            to={item.link}
            bg={item.background}
            justifyContent={['center', 'space-between', 'space-between']}
            alignItems={'center'}
            height={['auto', '80px', '80px']}
            minHeight={['128px','128px', 'auto', 'auto']}
            borderRadius={8}
            px={4}
            flexDir={[item.image ? 'column-reverse' : 'column', 'row', 'row']}
            _hover={{
              boxShadow :"0px 7px 15px 0px rgba(32, 32, 32, 0.09)"
            }}
          >
            {item.image && (
              <Box
                maxW={150}
                as="figure"
                m={0}
                alignSelf={['center','flex-end','flex-end']}
                flexShrink={0}
              >
                <Image w={'full'} m={0} src={item.image} />
              </Box>
            )}
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              flexDir={'column'}
              flex={1}
              textAlign={'center'}
              color={'white'}
            >
              {item.tag && !item.tagImage && (
                <Text
                  whiteSpace={'nowrap'}
                  px={4}
                  py={1}
                  display={'inline-block'}
                  textAlign={'center'}
                  color={item.tagTextColor}
                  bg={item.tagBackground}
                  rounded={'full'}
                >
                  {item.tag}
                </Text>
              )}

              {item.tagImage && (
                <Box>
                  <Image maxW={115} w={'full'} m={0} src={item.tagImage} />
                </Box>
              )}
              {item.title && (
                <Text 
                fontWeight={600} 
                className='text-balance' pt={2} fontSize={'18px'} textAlign={'center'} color={item.textColor}>
                  {item.title}
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default SpecialsGrid;

Builder.registerComponent(SpecialsGrid, {
  name: 'Specials Grid',
  inputs: [
    {
      name: 'header',
      type: 'string'
    },
    {
      name: 'desktopColumns',
      type: 'number',
      defaultValue: 4
    },
    {
      name: 'mobileColumns',
      type: 'number',
      defaultValue: 2
    },
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'background',
          type: 'color',
          defaultValue: '#1480A5'
        },
        {
          name: 'textColor',
          type: 'color',
          defaultValue: '#fff'
        },
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Title'
        },
        {
          name: 'tag',
          type: 'string'
        },
        {
          name: 'tagTextColor',
          type: 'color',
          defaultValue: '#fff'
        },
        {
          name: 'tagBackground',
          type: 'color',
          defaultValue: '#fff'
        },
        {
          name: 'tagImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          helperText:
            'This will replace the tag text if provided. Leave blank to use text instead.'
        },
        {
          name: 'link',
          type: 'string',
          defaultValue: '#'
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
        }
      ]
    }
  ]
});
