import { Box, Button, Stack, Text } from '@chakra-ui/react';
import React, {useState} from 'react';
import { isMadPawsStore } from '../../../common/util';

const SeoContent = ({ title, excerpt, readMore }) => {
  const [viewMore, setViewMore] = useState(true);
  return (
    <Stack spacing={4} mt={4}>
      <Text textAlign={'center'} fontWeight={'bold'}>
        {title}
      </Text>
      {excerpt && (
        <Box
          className="text-center text-balance"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></Box>
      )}
      {readMore && (
        <>
          {viewMore && (
            <Box
              className="text-center text-balance"
              dangerouslySetInnerHTML={{ __html: readMore }}
            ></Box>
          )}

          <Button
            variant={'link'}
            color={isMadPawsStore() ? 'var(--primary-default)' : 'var(--blue-06)'}
            onClick={() => setViewMore(!viewMore)}
          >
            {viewMore ? 'Read Less' : 'Read More'}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default SeoContent;
